import React, { useEffect, useState } from 'react'
import { useLazyQuery } from '@apollo/client'

import { useQueryString } from 'frontend/hooks'
import { PolarisDialog } from 'frontend/components'
import * as GQL_TAGS from 'frontend/entity-hooks/usePayerPolicyDocument/gql-tags'

import { PrefilledPolicy } from './modals'

const ImportModal = () => {
  const [document, setDocument] = useState([])
  const {
    importModal,
    payer_policy_document_id,
  }: any = useQueryString()

  const [getDocument] = useLazyQuery(GQL_TAGS.GET_PAYER_POLICY_DOCUMENTS, {
    variables: { input: { id: payer_policy_document_id } },
    onCompleted: (data) => {
      setDocument(data.payerPolicyDocuments)
    },
  })

  useEffect(() => {
    if (importModal && payer_policy_document_id) {
      getDocument()
    }
  }, [importModal, payer_policy_document_id])

  if (!importModal || !document[0]) return null

  return (
    <PolarisDialog>
      <PrefilledPolicy document={document} />
    </PolarisDialog>
  )
}

export default ImportModal
