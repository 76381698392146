import React from 'react'

import { CondensedDataTable as DataTable } from '@pulse-analytics/pulse-design-system'

import getExportConfig from './getExportConfig'

export const EditableDataTable = ({
  hasExport = true,
  hasReset = true,
  hasRowCount = true,
  isEditable = true,
  isPaginated = true,
  colConfig,
  editAccessor = undefined,
  excelLabel = '',
  exportConfig = undefined,
  ...props
}) => {
  const injectedExportConfig = getExportConfig({
    colConfig: exportConfig ?? colConfig,
    excelLabel,
  })

  if (isEditable && !props?.editAccessor) editAccessor = 'id'

  return (
    <DataTable
      {...props}
      hasExport={hasExport}
      hasReset={hasReset}
      hasRowCount={hasRowCount}
      isEditable={isEditable}
      isFocusable
      isHoverable
      isPaginated={isPaginated}
      colConfig={colConfig}
      editAccessor={editAccessor}
      exportConfig={injectedExportConfig}
    />
  )
}
