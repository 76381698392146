// ! This file returns the gql response and count can be found under documentCount or productCount resolvers

import * as CRUD_UTILS from 'frontend/api/utils'
import { useQueryString } from 'frontend/hooks'
import * as GQL_TAGS from '../gql-tags'

export const useCount = () => {
  let {
    page,
    view,
    payer_policy_document_id,
    product_id,
    importModal,
    ...filterValues
  }: any = useQueryString()

  const { data: countData } = CRUD_UTILS.useRead({
    tag: GQL_TAGS.GET_PAYER_POLICY_DOCUMENTS_COUNT,
    input: filterValues,
    options: { networkPolicy: 'network-only' },
  })

  const count = countData?.payerPolicyDocumentsCount ?? 0

  return { count }
}
