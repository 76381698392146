import { useLazyQuery } from '@apollo/client'

import { useQueryString } from 'frontend/hooks'

import * as GQL_TAGS from '../gql-tags'
import { PAGE_SIZE } from '../constants'

interface ProductData {
  products: any[]
  getProducts: any
  areProductsLoading: boolean
}

export const useProductData = (): ProductData => {
  let {
    page,
    view,
    payer_policy_document_id,
    product_id,
    ...filterValues
  }: any = useQueryString()

  const [
    getProducts,
    { loading: areProductsLoading, data },
  ] = useLazyQuery(GQL_TAGS.GET_PAYER_POLICY_PRODUCTS, {
    variables: { input: { page: Number(page), page_size: PAGE_SIZE, filterValues } },
  })

  let products = []

  if (data) {
    products = data.payerPolicyProducts
  }

  return {
    products,
    getProducts,
    areProductsLoading,
  }
}
