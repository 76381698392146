import * as GQL_TAGS from './gql-tags'

export const getTeamAuth0UserConfigs = (
  deleteOptions = {},
  queryInput: any
) => ({
  createConfig: {
    tag: GQL_TAGS.CREATE_TEAM_AUTH0_USER,
    readTag: GQL_TAGS.GET_TEAM_AUTH0_USERS,
  },
  updateConfig: {
    tag: GQL_TAGS.UPDATE_TEAM_AUTH0_USER,
  },
  deleteConfig: {
    tag: GQL_TAGS.DELETE_TEAM_AUTH0_USER,
    readTag: GQL_TAGS.GET_TEAM_AUTH0_USERS,
    mutationOptions: deleteOptions,
  },
  readConfig: {
    tag: GQL_TAGS.GET_TEAM_AUTH0_USERS,
    input: queryInput,
  },
})
