import { Button } from '@pulse-analytics/pulse-design-system'
import styled from '@emotion/styled'

import { Colors } from 'frontend/utils/pulseStyles'
import { usePayerPolicyDocument } from 'frontend/entity-hooks'
import { useClearSearchStringParam } from 'frontend/hooks'

const Wrapper = styled.div({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
})

const cancelButtonStyle = {
  padding: '9px 12px',
  backgroundColor: Colors.GRAY_50,
  marginRight: 12,
  height: 36,
}

const saveButtonStyle = {
  padding: '9px 12px',
  height: 36,
}

export const ActionBar = ({ fieldState }) => {
  const clearSearchStringParam = useClearSearchStringParam()
  const { createDocument: create } = usePayerPolicyDocument()

  const cancel = () => clearSearchStringParam(['importModal'])

  const submit = () => {
    const input = {
      variables: {
        input: {
          file: fieldState.file,
          payer: fieldState.payer,
          book: fieldState.book,
          benefit_type: fieldState.benefit_type,
          document_type: fieldState.document_type,
          products: fieldState.products,
        },
      },
    }
    create(input)
    cancel()
  }

  return (
    <Wrapper>
      <Button
        type="secondary"
        onClick={cancel}
        color={Colors.GRAY_500}
        style={cancelButtonStyle}
      >
        Cancel
      </Button>
      <Button type="primary" onClick={submit} style={saveButtonStyle}>
        Save
      </Button>
    </Wrapper>
  )
}
