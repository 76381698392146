import { DataProxy } from '@apollo/client'

import { GET_PAYER_POLICY_DOCUMENTS } from 'frontend/entity-hooks/usePayerPolicyDocument/gql-tags'

export const addDocumentToCache = (
  cache: DataProxy,
  data: any,
  payer_policy_document_id: string,
) => {
  const newDocument: any = Object.values(data.data)[0]
  const newDocumentKey = getDocumentKey(newDocument)
  const variables = {
    input: {
      id: payer_policy_document_id,
    },
  }
  const query: any = cache.readQuery({
    query: GET_PAYER_POLICY_DOCUMENTS,
    variables,
  })
  const queryName = Object.keys(query)[0]

  const updatedDocuments = query[queryName].map((document: any) => {
    const documentKey = getDocumentKey(document)
    if (documentKey === newDocumentKey) {
      return newDocument
    }
    return document
  })

  cache.writeQuery({
    query: GET_PAYER_POLICY_DOCUMENTS,
    variables,
    data: {
      [queryName]: [...updatedDocuments],
    },
  })
}

const getDocumentKey = ({
  payer_organization,
  book_of_business,
  benefit_type,
  document_type,
  products,
}: any) => {
  const productNames = products
    .map(({ product: { brand_name } }: any) => brand_name)
    .join()
  return `${payer_organization.name}|${book_of_business.name}|${benefit_type}|${document_type}|${productNames}`
}
