import React from 'react'
import styled from '@emotion/styled'
import { transparentize } from 'polished'

import { Spinner } from 'frontend/components'
import { useQueryString } from 'frontend/hooks'
import { usePayerPolicyDocument } from 'frontend/entity-hooks'

import { exportExcelSheet } from './exportExcelSheet'

const CLASS_NAME = 'payer-policy-export-button'

const Wrapper = styled.div({
  position: 'relative',
})

const StyledButton = styled.button({}, ({ disabled }) => ({
  display: 'inline-flex',
  alignItems: 'center',
  flexDirection: 'row',
  backgroundColor: disabled
    ? transparentize(0.9, '#0668D9')
    : transparentize(0.85, '#0668D9'),
  whiteSpace: 'nowrap',
  cursor: 'pointer',
  marginLeft: 8,
  padding: 10,
  height: 34,
  borderRadius: 4,
}))

const ButtonLabel = styled.div({}, ({ isLoading }) => ({
  color: isLoading ? transparentize(0.4, '#0668D9') : '#0668D9',
  fontSize: 12,
  fontWeight: 700,
  marginRight: isLoading ? 6 : 0,
}))

export const ExportButton = () => {
  const { view } = useQueryString()
  const {
    export: {
      getProducts,
      getDocuments,
      areProductsLoading,
      areDocumentsLoading,
    },
  } = usePayerPolicyDocument()

  const isLoading = areProductsLoading || areDocumentsLoading

  const onClick = () => {
    if (view === 'policies') {
      getDocuments().then((response) => {
        exportExcelSheet({
          data: response?.data?.payerPolicyDocuments || [],
          view,
        })
      })
    } else if (view === 'criteria') {
      getProducts().then((response) => {
        exportExcelSheet({
          data: response?.data?.payerPolicyProducts || [],
          view,
        })
      })
    }
  }

  return (
    <Wrapper>
      <StyledButton
        disabled={isLoading}
        className={CLASS_NAME}
        onClick={onClick}
      >
        <ButtonLabel isLoading={isLoading}>Export to Excel</ButtonLabel>
        {isLoading && <Spinner />}
      </StyledButton>
    </Wrapper>
  )
}
