import React, { useEffect } from 'react'
import _ from 'lodash'

import { useQueryString, useRewriteSearchString } from 'frontend/hooks'

import Policies from './Policies'
import { usePayerPolicyDocument } from 'frontend/entity-hooks'
import { formatColumnData } from './column-config/formatColumnData'
import { getColumnConfig } from './column-config'
import { getExportConfig } from './getExportConfig'

const Container = () => {
  const {
    page,
    view,
    payer_policy_document_id,
    ...filterValues
  } = useQueryString()
  const { documents: data, areDocumentsLoading: isLoading, getDocuments }: any = usePayerPolicyDocument()
  useEffect(() => {
    if (view === 'policies' && !payer_policy_document_id) getDocuments()
  }, [view, page, payer_policy_document_id, JSON.stringify(filterValues)])

  if (view !== 'policies') return null

  const formattedData = formatColumnData(data)
  const exportConfig = getExportConfig()
  const colConfig = getColumnConfig({ setModalType: useRewriteSearchString() })

  let filterAccessorsToShow = []
  if (!_.isEmpty(colConfig)) {
    filterAccessorsToShow = colConfig.reduce((acc: any[], { columns }) => {
      columns.forEach(({ accessor }: any) => {
        acc.push(accessor)
      })
      return acc
    }, [])
  }

  return (
    <Policies
      data={formattedData}
      isLoading={isLoading}
      colConfig={colConfig}
      exportConfig={exportConfig}
      filterAccessorsToShow={filterAccessorsToShow}
    />
  )
}

export default Container
