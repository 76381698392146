import React, { createContext } from 'react'

import SingleActionDialog from 'frontend/components/Dialog/SingleActionDialog'

import UserList from './UserList'
import { useModalClient, useModalForm, useModalRerender, FormState } from './hooks'
import { Description } from './Description'
import Form from './Form'

interface Props {
  cancel: () => void
  isOpen: boolean
  usersToMove: any[]
}

const INITIAL_FORM_STATE: FormState = {
  client: undefined,
  teams: [],
  users: [],
  primaryTeam: undefined,
}

export const FormContext = createContext<{
  form: FormState,
  setForm: React.Dispatch<React.SetStateAction<FormState>>
}>({
  form: INITIAL_FORM_STATE,
  setForm: () => {},
})

const MoveModal = ({ isOpen, cancel, usersToMove }: Props) => {
  const {
    form,
    setForm,
    submit,
    isSubmitting,
    isInputPartial,
    resetForm,
  } = useModalForm(usersToMove)
  
  useModalRerender(form, setForm, resetForm, isOpen)

  const { data: client } = useModalClient(setForm)

  if (!isOpen) return null

  return (
    <SingleActionDialog
      header={`Move ${client?.name} Users`}
      backgroundColor="white"
      submitText={'Move'}
      subheader="Remember to push permissions after submitting."
      submitHandler={submit}
      cancelHandler={cancel}
      isSubmitDisabled={isInputPartial || isSubmitting}
    >
      <Description />
      <div style={{ padding: 12, margin: 12, marginTop: 0 }}>
        <UserList />
        <FormContext.Provider value={{ form, setForm }}>
          <Form />
        </FormContext.Provider>
      </div>
    </SingleActionDialog>
  )
}

export default MoveModal
