import React from 'react'
import { useQuery } from '@apollo/client'

import { Dialog, Spinner } from 'frontend/components'
import * as GQL_TAGS from 'frontend/entity-hooks/usePayerPolicyDocument/gql-tags'

import PDFViewer from './PDFViewer'

interface InnerContainerProps {
  payer_policy_document_id: string
}

const InnerContainer: React.FC<InnerContainerProps> = ({ payer_policy_document_id }) => {
  const { loading: isLoading, data: documentData } = useQuery(
    GQL_TAGS.GET_PAYER_POLICY_DOCUMENTS,
    {
      variables: { input: { id: payer_policy_document_id } },
      fetchPolicy: 'network-only',
    }
  )

  let document = []
  if (documentData) {
    document = documentData.payerPolicyDocuments
  }

  if (isLoading || !document.length) {
    return (
      <Dialog
        contentStyle={{
          alignItems: 'center',
          backgroundColor: 'transparent',
          display: 'flex',
          justifyContent: 'center',
        }}
        contentWrapperStyle={{
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Spinner />
      </Dialog>
    )
  }

  return <PDFViewer document={document} />
}

export default InnerContainer
