import React, { useEffect } from 'react'

import { useRewriteSearchString, useQueryString } from 'frontend/hooks'
import { usePayerPolicyDocument } from 'frontend/entity-hooks'

import Criteria from './Criteria'
import { formatColumnData } from './column-config/formatColumnData'
import { getExportConfig } from './getExportConfig'
import { getColumnConfig } from './column-config'

const Container = () => {
  const {
    page,
    view,
    payer_policy_document_id,
    ...filterValues
  }: any = useQueryString()
  
  const {
    products: latestPayerPolicyDocuments,
    areProductsLoading: isLoading,
    getProducts,
  }: any = usePayerPolicyDocument()

  useEffect(() => {
    if (view === 'criteria' && !payer_policy_document_id) {
      getProducts()
    }
  }, [view, page, payer_policy_document_id, JSON.stringify(filterValues)])

  if (view !== 'criteria') return null

  const formattedData = formatColumnData(latestPayerPolicyDocuments)
  const exportConfig = getExportConfig()
  const colConfig = getColumnConfig({ setModalType: useRewriteSearchString() })

  return (
    <Criteria
      data={formattedData}
      isLoading={isLoading}
      exportConfig={exportConfig}
      colConfig={colConfig}
    />
  )
}

export default Container
