export const schema = `
  id
  pdf_hash
  payer_organization {
    id
    name
    slug
    name_tiny
    created_at
    updated_at
  }
  book_of_business {
    id
    name
    created_at
    updated_at
  }
  document_type
  benefit_type
  link
  source_link
  timestamp
  is_imported_document
  instruction {
    id
    navigation_instructions
    instruction_status
    payer_website_link
    automation_payer_website_link
    tracking_method
    products {
      id
      product {
        id
        generic_name
        brand_name
        logo_link
        color
        messaging
      }
      template_name
      search_term
      automation_search_term
      automation_type
      automation_status
      payer_policy_project {
        id
        name
        tdg_internal_note
        created_at
        updated_at
      }
      last_run_date
      tags
      assigned_policy_reviewers
      assigned_criteria_reviewers
      tdg_internal_note
      created_at
      updated_at
    }
    tdg_internal_note
    created_at
    updated_at
  }
  approved_date
  products {
    id
    product {
      id
      generic_name
      brand_name
      logo_link
      color
      messaging
    }
    policy_status
    has_language_changes
    tdg_internal_note
    payer_policy_project {
      id
      name
      tdg_internal_note
      created_at
      updated_at
    }
    assigned_policy_reviewers
    assigned_criteria_reviewers
    created_at
    updated_at
  }
  tdg_internal_note
  created_at
  updated_at
`
