import React from 'react'
import styled from '@emotion/styled'
import { useAuth0 } from 'react-auth0-spa'
import { Button } from '@pulse-analytics/pulse-design-system'

import { Spinner } from 'frontend/components'
import superUsersById from 'frontend/utils/super-users'

const BUTTON_STYLE = {
  padding: '5px 8px',
}

const Wrapper = styled.div({
  marginBottom: 2,
})

export const GetResetUrlButton = ({ userId, resetPasswordUrl }) => {
  const { user } = useAuth0()
  const isSuperUser = user.sub in superUsersById

  if (!isSuperUser) return null

  return (
    <Wrapper>
      <Button
        onClick={() => {
          resetPasswordUrl.get(userId)
        }}
        type="secondary"
        style={BUTTON_STYLE}
      >
        {resetPasswordUrl.isLoading && <Spinner />}
        Get Reset Password Link
      </Button>
    </Wrapper>
  )
}
