import { useLazyQuery } from "@apollo/client"
import { useQueryString } from "frontend/hooks"

import * as GQL_TAGS from "../gql-tags"

interface ExportedData {
  products: any
  documents: any
  getProducts: any
  getDocuments: any
  areProductsLoading: boolean
  areDocumentsLoading: boolean
}

export const useExportedData = (): ExportedData => {
  let {
    page,
    view,
    payer_policy_document_id,
    product_id,
    ...filterValues
  }: any = useQueryString()

  const [
    getProducts,
    { loading: areProductsLoading, data: productData },
  ] = useLazyQuery(GQL_TAGS.GET_PAYER_POLICY_PRODUCTS, {
    variables: { input: { filterValues } },
  })

  let products = []
  if (productData) {
    products = productData.payerPolicyProducts
  }

  const [
    getDocuments,
    { loading: areDocumentsLoading, data: documentData },
  ] = useLazyQuery(GQL_TAGS.GET_PAYER_POLICY_DOCUMENTS, {
    variables: { input: { filterValues } },
  })

  let documents = []
  if (documentData) {
    documents = documentData.payerPolicyProducts
  }

  return {
    products,
    documents,
    getProducts,
    getDocuments,
    areProductsLoading,
    areDocumentsLoading,
  }
}
