import { PAGE_SIZE } from './constants'
import {
  useCount,
  useDocumentData,
  useExportedData,
  useFilterOptions,
  useProductData,
} from './utils'

export const usePayerPolicyDocument = () => {
  return {
    ...useDocumentData(),
    ...useProductData(),
    export: useExportedData(),
    pageSize: PAGE_SIZE,
    ...useCount(),
    ...useFilterOptions(),
  }
}
