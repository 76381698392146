import { useQuery } from '@apollo/client'

import * as GQL_TAGS from '../gql-tags'

export const useFilterOptions = () => {
  const { data, loading: isLoadingFilterOptions } = useQuery(
    GQL_TAGS.GET_PAYER_POLICY_DOCUMENTS_FILTER_OPTIONS
  )

  let filterOptions = []
  if (data) {
    filterOptions = data.payerPolicyDocumentsFilterOptions
  }

  return {
    filterOptions,
    isLoadingFilterOptions,
  }
}
