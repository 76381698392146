import _ from 'lodash'

import { formatDateMonthYearDash } from 'frontend/utils/formatDate'

export const getOptions = ({
  accessor,
  labelMap,
  filterOptions,
  isLoadingFilterOptions,
  isDateColumn,
}: any) => {
  if (isLoadingFilterOptions) {
    return []
  }

  let optionsList = filterOptions[accessor]
  if (isDateColumn) {
    optionsList = optionsList.map((option: any) => {
      return {
        label: formatDateMonthYearDash(option.label),
        value: formatDateMonthYearDash(option.label),
      }
    })
    optionsList = _.uniqBy(optionsList, 'label')
  }

  if (labelMap) {
    const options = optionsList.map(
      ({ label, value }: { label: string; value: string }) => {
        return {
          label: labelMap[label],
          value: value,
        }
      }
    )

    return options
  }

  return optionsList
}
