import { useLazyQuery, useMutation } from '@apollo/client'

import * as CRUD_UTILS from 'frontend/api/utils'
import * as GQL_TAGS from '../gql-tags'
import { useQueryString } from 'frontend/hooks'
import { PAGE_SIZE } from '../constants'

interface DocumentData {
  documents: any[]
  getDocuments: any
  areDocumentsLoading: boolean
  createDocument: any
  isCreating: boolean
  updateDocument: any
  isUpdating: boolean
  createData: any
}

export const useDocumentData = (): DocumentData => {
  let {
    page,
    view,
    payer_policy_document_id,
    importModal,
    ...filterValues
  }: any = useQueryString()

  const [
    getDocuments,
    { loading: areDocumentsLoading, data },
  ] = useLazyQuery(GQL_TAGS.GET_PAYER_POLICY_DOCUMENTS, {
    variables: { input: { page: Number(page), page_size: PAGE_SIZE, filterValues } },
  })

  let documents = []
  if (data) {
    documents = data.payerPolicyDocuments
  }

  const [
    createDocument,
    { loading: isCreating, data: createData },
  ] = useMutation(GQL_TAGS.CREATE_PAYER_POLICY_DOCUMENT)

  const [updateDocument, { loading: isUpdating }] = CRUD_UTILS.useUpdate({
    tag: GQL_TAGS.UPDATE_PAYER_POLICY_DOCUMENT,
  })

  return {
    documents,
    getDocuments,
    areDocumentsLoading,
    createDocument,
    isCreating,
    updateDocument,
    createData,
    isUpdating,    
  }
}
