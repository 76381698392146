import { formatDateMonthYearDash } from 'frontend/utils/formatDate'
import { getPolicyStatus } from './getPolicyStatus'

export const formatColumnData = (data: any[]) => {
  return data.map(
    ({
      payer_organization,
      book_of_business,
      instruction,
      products,
      updated_at,
      ...rest
    }) => {
      const {
        productNames,
        filteredProducts,
        productPolicyStatuses,
      } = products.reduce(
        (acc: any, product: any) => {
          if (!product.product) return acc

          const brand_name = product.product?.brand_name || ''
          acc.filteredProducts.push(product)
          acc.productNames.push(brand_name)
          acc.productPolicyStatuses.push(product.policy_status)
          return acc
        },
        {
          productNames: [],
          filteredProducts: [],
          productPolicyStatuses: [],
        }
      )

      return {
        payer_organization_name: payer_organization.name,
        book_of_business_name: book_of_business.name,
        instruction_status: instruction.instruction_status,
        payer_website_link: instruction.payer_website_link,
        products: filteredProducts,
        product_names: productNames,
        policy_assignees: getAssignees(filteredProducts),
        updated_at: formatDateMonthYearDash(updated_at),
        project: getDocumentProjectNames(filteredProducts),
        product_policy_status: getPolicyStatus(productPolicyStatuses)
          .policyStatus,
        ...rest,
      }
    }
  )
}

const getDocumentProjectNames = (products: any) => {
  const projectNames = new Set()
  products.forEach((product: any) => {
    {
      if (product.payer_policy_project) {
        projectNames.add(product.payer_policy_project.name)
      }
    }
  })

  return Array.from(projectNames)
}

const getAssignees = (products: any) => {
  const assignees = new Set()
  products.forEach((product: any) => {
    {
      if (product.assigned_policy_reviewers) {
        product.assigned_policy_reviewers.forEach((assignee: any) => {
          assignees.add(assignee)
        })
      }
    }
  })

  return Array.from(assignees)
}
