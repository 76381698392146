import { useEffect } from 'react'

export const useModalRerender = (
  form: any,
  setForm: any,
  resetForm: () => void,
  isOpen: boolean
) => {
  useEffect(() => {
    const isPrimaryTeamValid = form.teams.find(
      ({ value }: { value: any }) => value === form.primaryTeam?.value
    )
    if (form.teams && !isPrimaryTeamValid) {
      setForm((prev: any) => ({ ...prev, primaryTeam: form.teams[0] || null }))
    }
  }, [form.teams])

  useEffect(() => {
    return resetForm
  }, [isOpen])
}
