import React, { useState } from 'react'

import { useQueryString } from 'frontend/hooks'

import { Content } from './Content'
import { Header } from './Header'

export const PrefilledPolicy = ({ document }: any) => {
  const { payer_policy_document_id }: any = useQueryString()

  const payerPolicyDocument = document[0]
  const [fieldState, setFieldState] = useState<any>({
    payer_organization: payerPolicyDocument.payer_organization,
    book_of_business: payerPolicyDocument.book_of_business,
    benefit_type: payerPolicyDocument.benefit_type,
    document_type: payerPolicyDocument.document_type,
    document_products: payerPolicyDocument.products,
    tracking_method: payerPolicyDocument.instruction.tracking_method,
    document: payer_policy_document_id,
  })

  const handleFieldChange = ({ name, value }: any) => {
    const newFieldState = {
      ...fieldState,
      [name]: value,
    }
    setFieldState(newFieldState)
  }

  return (
    <div>
      <Header fieldState={fieldState} />
      <Content onChange={handleFieldChange} fieldState={fieldState} />
    </div>
  )
}
