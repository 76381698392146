import React from 'react'
import styled from '@emotion/styled'

import { Select } from 'frontend/components'
import { Colors } from 'frontend/utils/pulseStyles'

import { selectStyles } from '../selectStyles'

interface Props {
  fieldState: any
  onChange: any
}

const Wrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  padding: '8px 0',
  marginBottom: 24,
})

const Label = styled.label({
  fontSize: 14,
  fontWeight: 500,
  color: Colors.GRAY_500,
  marginBottom: 8,
  width: '50%',
})

const LABEL_TEXT = 'Tracking Method'
const SELECT_PLACEHOLDER = 'Tracking Method'
const FIELD_NAME = 'tracking_method'

const OPTIONS = [
  {
    label: 'Automated',
    value: 'automated',
  },
  {
    label: 'Manual',
    value: 'manual',
  },
  {
    label: 'Not Tracked',
    value: 'not_tracked',
  },
]

export const TrackingMethod = ({ fieldState, onChange }: Props) => {
  const { tracking_method } = fieldState
  const defaultValue = OPTIONS.find(
    (option) => option.value === tracking_method
  )
  const handleChange = ({ value }: any) => {
    onChange({
      name: FIELD_NAME,
      value,
    })
  }

  return (
    <Wrapper>
      <Label>{LABEL_TEXT}</Label>
      <Select
        options={OPTIONS}
        value={defaultValue}
        styles={selectStyles}
        placeholder={SELECT_PLACEHOLDER}
        onChange={handleChange}
      />
    </Wrapper>
  )
}
