import { useState } from 'react'
import _ from 'lodash'

import { useTeamAuth0User } from 'frontend/entity-hooks/ember'

export type FormState = {
  client: { value: string; label: string } | undefined
  teams: { value: string; label: string }[]
  users: any[]
  primaryTeam: { value: string; label: string } | undefined
}

export type FormStateInput = {
  client_id: string | undefined
  primary_team_id: string | undefined
  team_ids: string[] | undefined
  user_ids: string[] | undefined
}

export const useModalForm = (usersToMove: any) => {
  const defaultFormState = getDefaultFormState(usersToMove)
  const [form, setForm] = useState<FormState>(defaultFormState)

  const { moveClient, isMovingClient: isSubmitting } = useTeamAuth0User()

  const isInputPartial = getIsInputPartial(form)

  const submit = () => {
    if (!isInputPartial) moveClient({ variables: { input: getFormInput(form) } })
  }

  return {
    form,
    setForm,
    isSubmitting,
    isInputPartial,
    submit,
    resetForm: () => setForm(defaultFormState),
  }
}

const getDefaultFormState = (usersToMove: any): FormState => {
  return {
    client: undefined,
    teams: [],
    users: usersToMove,
    primaryTeam: undefined,
  }
}

const getIsInputPartial = (form: FormState) => {
  return !form.client ||
    !form.primaryTeam ||
    _.isEmpty(form.users) ||
    _.isEmpty(form.teams)
}

const getFormInput = ({client, primaryTeam, teams, users}: FormState): FormStateInput => {
  return {
    client_id: client?.value,
    primary_team_id: primaryTeam?.value,
    team_ids: teams.map(({ value }) => value),
    user_ids: users.map(({ id }) => id),
  }
}

