import { useLazyQuery, useMutation } from '@apollo/client'
import { useState } from 'react'

import { GET_RESET_USER_PASSWORD_URL, RESET_USER_PASSWORD } from './gql-tags'

export const usePulseToolsUser = (user_id: string) => {
  const [userResetUrls, setUserResetUrls] = useState({})

  const [resetPassword, { loading: isResettingPassword }] = useMutation(
    RESET_USER_PASSWORD,
    {
      onCompleted: () => {
        alert(
          'An email has been sent to this user to reset their password. If the user fails to receive the email, you will be sent an email with a link to give to the user.'
        )
      },
      onError: () => {
        alert('Failed to reset password. Please try again.')
      },
    }
  )

  const [getResetPasswordUrl, { loading, data }] = useLazyQuery(
    GET_RESET_USER_PASSWORD_URL,
    {
      onCompleted: () => {
        setUserResetUrls({
          ...userResetUrls,
          [user_id]: data?.passwordResetUrl,
        })

        setTimeout(() => {
          setUserResetUrls({ ...userResetUrls, [user_id]: null })
        }, 20000)
      },
      fetchPolicy: 'network-only',
    }
  )

  return {
    resetPasswordUrl: {
      isLoading: loading,
      data: userResetUrls as { [key: string]: string },
      get: (user_id: string) => getResetPasswordUrl({ variables: { auth0_id: user_id } }),
    },    
    resetPassword,
    isResettingPassword,
  }
}
