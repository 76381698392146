import { CrudHookOptions } from '../../../interfaces/CrudHookOptions'
import * as CRUD_UTILS from '../../../api/utils'
import * as GQL_TAGS from './gql-tags'

import { getTeamAuth0UserConfigs } from './getTeamAuth0UserConfigs'
import { useMutation } from '@apollo/client'

export const useTeamAuth0User = ({
  deleteOptions,
  queryInput,
}: CrudHookOptions = {}) => {

  const {
    createConfig,
    deleteConfig,
    updateConfig,
    readConfig,
  } = getTeamAuth0UserConfigs(deleteOptions, queryInput)

  const [create, { loading: isCreating }] = CRUD_UTILS.useCreate(createConfig)
  const [destroy, { loading: isDeleting }] = CRUD_UTILS.useDelete(deleteConfig)
  const [update, { loading: isUpdating }] = CRUD_UTILS.useUpdate(updateConfig)
  const [moveClient, { loading: isMovingClient }] = useMutation(GQL_TAGS.MOVE_TEAM_AUTH0_USERS_CLIENT, {
    onError: (error) => {
      const message = error.message ? JSON.parse(error.message).message : 'An error occurred'
      alert(message)
    },
  })

  const { data: teamAuth0UserData, loading: isLoading } = CRUD_UTILS.useRead(readConfig)
  const teamAuth0UsersData = teamAuth0UserData ? Object.values(teamAuth0UserData)[0] : []


  return {
    moveClient,
    isMovingClient,
    create,
    isCreating,
    update,
    isUpdating,
    destroy,
    isDeleting,
    data: teamAuth0UsersData,
    isLoading,
  }
}
