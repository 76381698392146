import React, { useState } from 'react'
import Spinner from 'frontend/components/Spinner'
import FileSaver from 'file-saver'
import { useFlags } from 'launchdarkly-react-client-sdk'

import { Spacing } from 'frontend/utils/pulseStyles'
import { convertIsoShortToDateObj } from 'frontend/utils/formatDate'
import { useAuth0 } from '../../../../react-auth0-spa'
import superUsersById from 'frontend/utils/super-users'
import Color from '../../../utils/color'

import {
  SectionContainer,
  SectionHeader,
  StyledButton,
} from '../styledComponents'

const KITE_EXPORT_URL = '/api/kite-export'
const KITE_MISSING_PAYERS_URL = '/api/kite-export-missing-payers'

const KiteBrandDotComExport = () => {
  const { user } = useAuth0()
  const isSuperUser = user.sub in superUsersById
  const { shouldShowKiteExport } = useFlags()
  const { accessToken } = useAuth0()
  const [kiteDate, setKiteDate] = useState(undefined)
  const [isKiteScriptLoading, setIsKiteScriptLoading] = useState(false)
  const [isMissingPayerLoading, setIsMissingPayerLoading] = useState(false)

  if (!shouldShowKiteExport) return null

  const clickHandler = (url, setLoadingStatusFn) => async (e) => {
    e.preventDefault()
    setLoadingStatusFn(true)

    await fetch(url, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
    })
      .then(async (response) => ({
        blob: await response.blob(),
        filename: response.headers
          .get('Content-Disposition')
          .split('filename=')[1],
      }))
      .then(({ blob, filename }) => {
        FileSaver.saveAs(blob, filename)
      })
      .catch(console.error)

    setLoadingStatusFn(false)
  }

  const handleKiteDateChange = (e) => {
    setKiteDate(e.target.value)
  }

  const shouldDisableDownload = !Boolean(kiteDate)

  return (
    <SectionContainer>
      <SectionHeader>Kite Brand.com Export</SectionHeader>
      <div>
        <span style={{ paddingRight: 12 }}>Select date: </span>
        <input
          name="date"
          type="date"
          value={kiteDate}
          onChange={handleKiteDateChange}
          style={{ marginBottom: Spacing.LARGE, marginRight: Spacing.LARGE }}
        />
      </div>
      <StyledButton
        disabled={shouldDisableDownload}
        size="small"
        onClick={clickHandler(
          KITE_EXPORT_URL + '/' + convertIsoShortToDateObj(kiteDate),
          setIsKiteScriptLoading
        )}
      >
        <span style={{ margin: '0 4px 0 6px' }}>Download</span>
        {isKiteScriptLoading && <Spinner fill={Color.PRIMARY} />}
      </StyledButton>
      {isSuperUser && (
        <StyledButton
          style={{ marginLeft: Spacing.LARGE }}
          disabled={shouldDisableDownload}
          size="small"
          onClick={clickHandler(
            KITE_MISSING_PAYERS_URL + '/' + convertIsoShortToDateObj(kiteDate),
            setIsMissingPayerLoading
          )}
        >
          <span style={{ margin: '0 4px 0 6px' }}>
            Download Unmapped Payers
          </span>
          {isMissingPayerLoading && <Spinner fill={Color.PRIMARY} />}
        </StyledButton>
      )}
      {shouldDisableDownload && (
        <span style={{ color: 'red' }}>Select date to download</span>
      )}
    </SectionContainer>
  )
}

export default KiteBrandDotComExport
