import React from 'react'

import { useQueryString } from 'frontend/hooks'

import InnerContainer from './InnerContainer'

const OuterContainer = () => {
  const { payer_policy_document_id } = useQueryString()

  if (!payer_policy_document_id) return null

  return <InnerContainer payer_policy_document_id={payer_policy_document_id as string} />
}

export default OuterContainer
