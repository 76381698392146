import React from 'react'
import styled from '@emotion/styled'

import { Colors } from 'frontend/utils/pulseStyles'

import { BenefitType } from './BenefitType'
import { Book } from './Book'
import { DocumentType } from './DocumentType'
import { FileSelect } from './FileSelect'
import { Payer } from './Payer'
import { TrackingMethod } from './TrackingMethod'

interface Props {
  onChange: any
  fieldState: any
}

const TITLE_TEXT = 'Policy Details'

const Wrapper = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'column',
  borderRadius: 6,
  backgroundColor: Colors.WHITE,
  padding: 24,
  height: 400,
  width: 464,
})

const Border = styled.div({
  borderBottom: `1px solid #CEDBEA`,
  marginBottom: 24,
})

const Title = styled.h1({
  fontSize: 16,
  fontWeight: 500,
  marginBottom: 24,
})

export const PolicyDetails = ({ onChange, fieldState }: Props) => {
  return (
    <Wrapper>
      <Title>{TITLE_TEXT}</Title>
      <FileSelect onChange={onChange} />
      <Border />
      <Payer fieldState={fieldState} />
      <Book fieldState={fieldState} />
      <BenefitType fieldState={fieldState} />
      <DocumentType fieldState={fieldState} />
      <TrackingMethod fieldState={fieldState} onChange={onChange} />
    </Wrapper>
  )
}
